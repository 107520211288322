

$primary-color: #3d63ea;
$heading-color: #1a202c;

.title {
  font-size: 35px;
  font-weight: 700;
  color: $heading-color;
  // opacity: 0.9;
}
.primary-btn {
  background-color: $primary-color;
  padding: 8px 24px 10px 24px;
  font-size: 15px;
  border: none;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 0px 4px 49px 0px rgba(0, 0, 0, 0.15);
}
h1,
h2,
h3,
h4,
h5 {
  color: $heading-color;
  // opacity: 0.9;
  font-weight: 700;
}
p {
  color: #4A5568;
  font-size: 18px;
  font-weight: 300;
}
section {
  padding: 60px 0px;
}

@import "./common.scss";
@import "./sliders.scss";
@import "./home.scss";
