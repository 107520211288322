.banner {
  padding: 11rem 0px 5rem 0px;
  @media (max-width: 768px) {
    padding: 9rem 0px 3rem 0px;
  }
  .banner-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    justify-content: center;
    @media (max-width: 768px) {
      align-items: center;
      text-align: center;
    }
    h1 {
      color: $heading-color;
      margin-bottom: 10px;
      line-height: 1.3;
      font-size: 3rem;
      @media (max-width: 1280px) {
        font-size: 36px;
      }
      span {
        background: linear-gradient(90deg, #ff4c33 0%, #4069ff 66.67%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 800;
      }
    }
    p {
      line-height: 30px;
      padding-right: 20%;
      margin: 10px 0px 20px 0px;
      @media (max-width: 768px) {
        padding-right: 0;
      }
    }
    .contact {
      @extend .primary-btn;
      margin-top: 12px;
      box-shadow: 0px 4px 49px 0px rgba(0, 0, 0, 0.15);
      padding: 14px 28px;
    }
  }
  img {
    margin-left: auto;
    @media (max-width: 768px) {
      margin-top: 2rem;
    }
  }
}
.cta {
  padding: 8rem 0px 2rem 0px;
  position: relative;
  .cta-bg {
    background-color: $primary-color;
    border-radius: 14px;
    padding: 60px 0px;
    @media (max-width: 576px) {
      padding: 40px 20px;
    }
    &__flex {
      display: flex;
      justify-content: space-around;
      align-items: center;
      @media (max-width: 768px) {
        display: block;
        text-align: center;
      }
      .heading {
        font-weight: 700;
        font-size: 40px;
        line-height: 1.45;
        color: #fff;
        @media (max-width: 768px) {
          font-size: 28px;
          margin-bottom: 2.5rem;
        }
      }
      .consult-btn {
        @extend .primary-btn;
        background-color: #fff;
        color: $primary-color;
        padding: 16px 32px;
        font-size: 20px;
        font-weight: 800;
      }
    }
  }
  .circle-wrap {
    position: absolute;
    left: 9%;
    top: -42px;
    z-index: -1;
    .circle {
      background: linear-gradient(225deg, #F76680 0%, #6768DF 100%);
      width: 72px;
      height: 72px;
      border-radius: 100px;
    }
  }
}
.collaboration {
  position: relative;
  .line-box {
    max-width: 50%;
    margin: 0 auto;
    @media (max-width: 768px) {
      max-width: 100%;
    }
    .title {
      margin-bottom: 1rem;
      text-align: center;
      line-height: 1.4;
      position: relative;
      // &::after {
      //   content: "";
      //   display: inline-block;
      //   width: 100%;
      //   height: 20px;
      //   background-image: url("../../assets/images/home/line.png");
      //   background-size: contain;
      //   background-repeat: no-repeat;
      //   margin-left: 57%;
      //   @media (max-width: 576px) {
      //     margin-left: 0;
      //   }
      // }
      @media (max-width: 576px) {
        padding: 0;
      }
    }
    img {
      margin-left: 57%;
      margin-bottom: 4.5rem;
      @media (max-width: 768px) {
        margin-left: 60%;
      }
      @media (max-width: 576px) {
        margin-left: 60%;
        display: none;
      }
    }
  }
  
  .collaboration-box {
    max-width: 20%;
    flex: 0 0 20%;
    display: flex;
    align-items: center;
    margin-bottom: 3.5rem;
    @media (max-width: 992px) {
      max-width: 30%;
      flex: 0 0 30%;
    }
    @media (max-width: 576px) {
      max-width: 48%;
      flex: 0 0 48%;
    }
    .logo {
      width: 130px;
      object-fit: contain;
      @media (max-width: 992px) {
        margin: 0 auto;
      }
      @media (max-width: 576px) {
        margin: 0 auto;
        width: 110px;
      }
    }
    .logo2 {
      width: 90px;
    }
    .logo6 {
      width: 176px;
      @media (max-width: 576px) {
        width: 140px;
      }
    }
  }
  .circle-wrap {
    position: absolute;
    left: 33%;
    bottom: -42px;
    z-index: -1;
    .circle {
      background: linear-gradient(225deg, #F76680 0%, #6768DF 100%);
      width: 72px;
      height: 72px;
      border-radius: 100px;
    }
  }
}
.methoology {
  .title {
    margin-bottom: 3.8rem;
  }
  .row > * {
    @media (max-width: 768px) {
      width: 50%;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
  .my-col {
    margin-bottom: 24px;
    @media (max-width: 992px) {
      margin-bottom: 26px;
    }
    .card {
      border-radius: 6px;
      border: none;
      padding: 40px 26px;
      align-items: flex-start;
      background: #fff;
      height: 100%;
      box-shadow: 0px 4px 15px 5px rgba(0, 0, 0, 0.08);
      img {
        margin-bottom: 20px;
        // box-shadow: 0px 4px 13px rgba(196, 16, 254, 0.45);
      }
      .sub-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 29px;
      }
      .card-text {
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
        color: #4A5568;
        opacity: 1;
      }
    }
  }
}
.our-team {
  .desc {
    margin-bottom: 3.8rem;
    margin-top: 17px;
  }
  .card {
    border: none;
    max-width: 86%;
    text-align: center;
    @media (max-width: 768px) {
      max-width: 100%;
      margin-bottom: 3rem;
    }
    .card-img {
      margin-bottom: 20px;
      @media (max-width: 768px) {
        max-width: 84%;
        margin: 0 auto;
        margin-bottom: 20px;
      }
    }
    .name {
      font-weight: 700;
      font-size: 35px;
    }
    .position {
      opacity: 1;
      color: #3D63EA;
      font-weight: 500;
      font-size: 18px;
      margin: 10px 0px;
    }
    .para {
      font-weight: 500;
      font-size: 15px;
      line-height: 27px;
      color: #4A5568;
      opacity: 1;
    }
  }
}
.statistics {
  .statistics-bg {
    border-radius: 15px;
    background: #0f1731;
    padding: 70px 0px;
    @media (max-width: 768px) {
      padding: 60px 0px 10px 0px;
    }
    .row > * {
      @media (max-width: 768px) {
        width: 48%;
      }
    }
    .box {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @media (max-width: 768px) {
        margin-bottom: 3.5rem;
      }
      img {
        margin-bottom: 15px;
        width: 70px;
        height: 70px;
        object-fit: cover;
      }
      h3 {
        color: #fff;
      }
      p {
        color: #D1D1D1;
        opacity: 1;
        font-weight: 700;
      }
    }
  }
}
.advisory {
  .title {
    margin-bottom: 4rem;
  }
  .adv-col {
    margin-bottom: 26px;
    .card {
      border-radius: 20px;
      border: 5px solid #3d63ea;
      max-width: 90%;
      height: 100%;
      margin: 0 auto;
      @media (max-width: 768px) {
        max-width: 100%;
        margin-bottom: 2rem;
      }
      .card-img {
        height: 300px;
        object-fit: cover;
        border-radius: 14px 14px 0px 0px;
      }
      .card-body {
        background-color: white;
        border-radius: 14px;
        margin-top: -20px;
        padding: 22px 22px 10px 22px;
        .name {
          font-weight: 700;
          font-size: 20px;
        }
        .year {
          color: #656565;
          font-size: 17px;
          font-weight: 500;
          font-size: 17px;
          font-weight: 500;;
          font-size: 17px;
          font-weight: 500;
          margin-left: -2px;
          margin-bottom: 0px;
        }
        .text {
          color: #656565;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 7px;
        }
        .position {
          font-size: 15px;
          font-weight: 500;
          font-style: italic;
          margin-top: 8px;
        }
        .company {
          font-size: 16px;
          color: #5b5b5b;
          font-weight: 400;
          line-height: 27px;
        }
      }
    }
  }
}
.tech-stack {
  background-color: #ecf0ff;
  padding: 70px 0px;
  .title {
    margin-bottom: 3rem;
  }
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .logo-card {
      border-radius: 13px;
      box-shadow: rgba(0, 0, 0, 0.09);
      height: 94px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      max-width: 23%;
      flex: 0 0 23%;
      margin-right: 20px;
      margin-bottom: 20px;
      &:nth-child(even) {
        @media (max-width: 576px) {
          margin-right: 0;
        }
      }
      @media (min-width: 993px) and (max-width: 1198px) {
        max-width: 31%;
        flex: 0 0 31%;
      }
      @media (max-width: 992px) {
        max-width: 30%;
        flex: 0 0 30%;
      }
      @media (max-width: 576px) {
        max-width: 47%;
        flex: 0 0 47%;
        margin: 0px 16px 16px 0px;
      }
      .logo {
        width: 128px;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
        @media (max-width: 576px) {
          width: 100px;
        }
        &:hover {
          transition: 2s ease-in-out all;
        }
        &.logo2 {
          width: 164px;
          @media (max-width: 576px) {
            width: 118px;
          }
        }
        &.logo3 {
          margin-top: -12px;
        }
      }
    }
  }
}
.case-studies {
  .title {
    text-align: center;
    margin-bottom: 1rem;
  }
  .desc {
    margin-bottom: 3.8rem;
    text-align: center;
  }
  .my-col {
    padding-left: 0;
    padding-right: 0;
  }
  .my-row {
    margin: 0 auto;
    width: 97%;
    margin-bottom: 26px;
  }
  &__image {
    padding: 62px 22px;
    background-color: #4067F2;
    border-radius: 30px 0px 0px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      border-radius: 30px 30px 0px 0px;
    }

    &.image2 {
      background: #08AF54;
    }
    &.image3 {
      background-color: #FFA8A8;
    }
    > img {
      max-width: 100%;
    }
  }
  &__content {
    display: flex;
    height: 100%;
    flex-direction: column;
    background: #f1f2ff;
    justify-content: center;
    border-radius: 0px 30px 30px 0px;
    padding: 22px 4.5rem;
    @media (max-width: 768px) {
      border-radius: 0px 0px 30px 30px;
      padding: 022px 1.5rem;
    }
    &.content2 {
      background-color: #ecfff6;
    }
    &.content3 {
      background-color: #fff2f2;
    }
    .sub-title {
      font-weight: 800;
      font-size: 26px;
      color: #2D3748;
      opacity: 1;
      margin-bottom: 1rem;
      @media (max-width: 768px) {
        line-height: 1.5;
      }
    }
    .info {
      font-weight: 400;
      font-size: 17px;
      opacity: 1;
      color: #4A5568;
      line-height: 30px;
    }
    .link {
      color: $primary-color;
      font-size: 14px;
      font-weight: 600;
      align-items: center;
      text-decoration: none;
      svg {
        width: 18px;
        display: inline-block;
        margin-left: 10px;
      }
    }
  }
}
.faq {
  background: #f7f7fa;
  h2 {
    margin-bottom: 3rem;
    text-align: center;
  }
  .accordion-item {
    box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
    border-radius: 18px;
    padding: 17px 20px;
    background-color: #fff;
    border: none;
    margin-bottom: 23px;
    cursor: pointer;
    &.active {
      border: 2px solid #2262f3;
    }
    &__header {
      .que {
        color: #170f49;
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 0;
        line-height: 1.55;
        width: 85%;
        @media (max-width: 576px) {
          font-size: 17px;
        }
      }
      .icon-box-wrapper {
        width: 15%;
        .icon-box {
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          background-color: white;
          align-items: center;
          border-radius: 100px;
          margin-left: auto;
          box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
          &.active {
            background-color: #2262f3;
          }
          svg {
            width: 28px;
          }
        }
      }
    }
    .accordion-content {
      padding-top: 10px;
      .ans {
        color: #6F6C90;
        font-weight: 400;
        font-size: 16px;
        opacity: 1;
        line-height: 28px;
      }
    }
  }
}
.contact-form {
  background-color: #ecf0ff;
  &__main {
    background-color: #fff;
    border-radius: 8px;
    padding: 8px;
    .title {
      font-size: 23px;
      font-weight: 600;
      margin-bottom: 2rem;
      color: $primary-color;
    }
    .steps {
      background-color: $primary-color;
      border-radius: 8px;
      padding: 24px 50px 24px 50px;
      @media (max-width: 576px) {
        padding: 24px 30px 24px 30px;
      }
      .text {
        font-size: 26px;
        font-weight: 700;
        color: #fff;
        opacity: 1;
        margin-bottom: 2.5rem;
      }
      .steps-flex {
        display: flex;
        align-items: center;
        margin-bottom: 4rem;
        .circle-wrapper {
          width: 20%;
          .circle {
            width: 48px;
            height: 48px;
            border-radius: 100px;
            background-color: #fff;
            color: $primary-color;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            margin-right: 12px;
            position: relative;
            &::after {
              content: "";
              display: block;
              border: 1px dashed #fff;
              opacity: 0.5;
              height: 138%;
              width: 1px;
              position: absolute;
              top: 100%;
              @media (max-width: 380px) {
                height: 177%;
              }
            }
            &.last::after {
              height: 0;
              top: 0;
            }
          }
        }
        .info {
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          opacity: 1;
          margin-bottom: 0;
          width: 90%;
          @media (max-width: 380px) {
            margin-left: 12px;
            font-size: 12px;
          }
        }
      }
    }
    .content {
      padding: 30px 20px 0px 20px;
      @media (max-width: 768px) {
        padding: 30px 20px 30px 20px;
      }
      label {
        display: block;
        color: #8d8d8d;
        font-size: 14px;
        letter-spacing: 1px;
        margin-bottom: 7px;
      }
      .input-field {
        border: none;
        border-bottom: 1px solid #8d8d8d;
        width: 94%;
        font-size: 17px;
        padding: 8px 10px;
        color: #8d8d8d;
        &::placeholder {
          color: #c8c8c8;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .input-field2 {
        @extend .input-field;
        width: 97% !important;
      }
      textarea {
        @extend .input-field;
        resize: none;
        width: 97% !important;
      }
      .msg-button {
        display: flex;
        justify-content: flex-end;
        // align-items: flex-end;
      }
      // .msg-button .success-msg {
      //   color: #0a8742;
      //   font-weight: 500;
      //   font-size: 17px;
      //   text-align: left;
      //   width: 60%;
      //   margin-bottom: 0;
      // }
      .msg-button .send-btn {
        @extend .primary-btn;
        padding: 14px 22px;
        margin-top: 24px;
        margin-right: 17px;
      }
    }
  }
}
