.service-slider {
  background-color: #f9f9ff;
  padding: 60px 0px 7rem 0px;
  .title {
    @extend .title;
    text-align: center;
    margin-bottom: 3rem;
  }
  .slick-list {
    padding: 30px 60px !important;
    height: 418px !important;
    @media (max-width: 576px) {
      height: 506px !important;
      padding: 0 !important;
    }
    .slick-slide {
      margin: 0px 20px;
      width: 426px !important;
      @media (max-width: 576px) {
        width: 308px !important;
        margin: 0px 10px;
      }
      @media (max-width: 390px) {
        width: 274px !important;
      }
      @media (max-width: 350px) {
        width: 282px !important;
      }
      @media (max-width: 349px) {
        width: 238px !important;
      }
      &.slick-center {
        border: 2px solid;
        border-radius: 6px;
        // border-image: linear-gradient(90deg, #ff4c33, #4069ff 66.67%) 1;
        border-radius: 6px;
        border: 2px solid transparent;
        transform: scale(1.07);
        background: linear-gradient(90deg, #ff4c33, #4069ff) border-box;
        @media (max-width: 576px) {
          display: none;
        }
        .desc {
          opacity: 1 !important;
        }
      }
      .card {
        box-shadow: 0px 6px 16px 0px rgba(74, 58, 255, 0.19);
        padding: 44px 24px;
        border: none;
        border-radius: 6px;
        align-items: flex-start;
        height: 350px;
        @media (max-width: 576px) {
          width: 100% !important;
          height: 418px;
        }
        @media (max-width: 375px) {
          height: 452px;
        }
        @media (max-width: 349px) {
          height: 468px;
        }
        .card-img {
          margin-bottom: 23px;
          width: 60px;
          height: 60px;
          border-radius: 100px;
          border: 1px solid $primary-color;
          // background: linear-gradient(45deg, red, blue) border-box;
          // -webkit-mask: linear-gradient(transparent 0 0) padding-box,
          //   linear-gradient(#fff 0 0);
          // -webkit-mask-composite: xor;
          // mask-composite: exclude;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 36px;
            height: 36px;
            object-fit: contain;
          }
        }
        .sub-title {
          font-weight: 600;
          font-size: 18px;
          color: #2D3748;
          opacity: 1;
          margin-bottom: 12px;
        }
        .desc {
          font-size: 15px;
          line-height: 24px;
          font-weight: 400;
          color: #4A5568;
          opacity: 0.9;
        }
      }
    }
  }
  .slick-dots {
    top: 105%;
    @media (max-width: 576px) {
      top: 95%;
    }
    @media (max-width: 350px) {
      top: 100%;
    }
    li {
      width: 16px;
      height: 16px;
      border: 1px solid rgb(196, 196, 196);
      border-radius: 100px;
      button {
        width: 16px;
        height: 16px;
        // border: 1px solid rgb(196, 196, 196);
        border-radius: 100px;
        &::before {
          color: unset;
        }
      }
    }
    .slick-active {
      border-radius: 100px;
      background: linear-gradient(225deg, #f76680 0%, #406aff 100%);
      stroke-width: 1px;
      width: 16px;
      height: 16px;
      border-radius: 100px;
      border: none;
    }
  }
  .slick-prev {
    display: none !important;
  }
  .slick-next {
    display: none !important;
  }
}

// testimonial slider
.testimonials {
  padding: 70px 0px 100px 0px;
  .title {
    margin-bottom: 3rem;
    padding: 0 33%;
    line-height: 1.5;
    @media (max-width: 992px) {
      padding: 0;
    }
  }
  .slick-slider {
    .slick-next {
      right: 44px;
      @media (max-width: 768px) {
        right: 0;
      }
    }
    .slick-prev {
      left: 44px;
      @media (max-width: 768px) {
        left: 0;
      }
    }
    .slick-arrow {
      border: 1px solid $primary-color;
      width: 44px;
      height: 44px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .slick-prev::before {
      content: url("../../assets/images/common/left-arrow.png");
      font-size: 30px;
      color: rgb(129, 29, 29);
      position: relative;
      top: -2px;
      opacity: 1;
    }
    .slick-next::after {
      content: url("../../assets/images/common/right-arrow.png");
      font-size: 30px;
      color: rgb(129, 29, 29);
      position: relative;
      top: -1px;
      right: 0;
      left: -9px;
      opacity: 1;
      right: 0;
    }
  }
  .slick-dots {
    @media (max-width: 768px) {
      display: flex !important;
      justify-content: center;
      overflow-x: scroll;
      left: 0px;
      right: -22px;
      bottom: -40px;
      max-width: 82%;
      margin: 0 auto;
      &::-webkit-scrollbar {
        height: 4px;
        border-radius: 8px;
        
        background-color: #c1cefe;
      }
      &::-webkit-scrollbar-thumb {
        height: 4px;
        border-radius: 8px;
        background-color: $primary-color;
      }
    }
    li {
      margin: 0px 1rem;
      opacity: 0.6;
      width: 16%;
      @media (min-width: 1024px) and (max-width: 1279px) {
        margin: 0px 1rem;
        width: 13%;
      }
      @media (min-width: 769px) and (max-width: 1023px) {
        margin: 0px 1rem;
        width: 14%;
      }
      @media (max-width: 768px) {
        margin: 0px 1rem;
        margin-bottom: 12rem;
        width: 33%;
        padding-bottom: 30px;
      }
      &.slick-active {
        opacity: 1;
        .name {
          color: $primary-color;
          font-weight: 600;
        }
        .profile {
          color: #000;
        }
      }
      a {
        display: inline-block;
        margin: 0 auto;
        // width: max-content;
        img {
          margin: 0 auto;
        }
        .client-img {
          width: 68px;
          height: 68px;
          object-fit: cover;
          border-radius: 100px;
          margin-bottom: 15px;
          box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        }
        .star-img {
          width: 18px;
          height: 18px;
          margin-right: 6px;
          object-fit: cover;
        }
        .name {
          color: #424851;
          font-weight: 500;
          font-size: 17px;
          margin: 13px 0px 6px 0px;
        }
        .profile {
          color: #424851;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
  .slick-list {
    max-width: 47%;
    margin: 0 auto;
    @media (min-width: 1024px) and (max-width: 1279px) {
      max-width: 58%;
    }
    @media (min-width: 769px) and (max-width: 1023px) {
      max-width: 80%;
    }
    @media (max-width: 768px) {
      max-width: 90%;
    }
    .content {
      padding: 16px 42px 40px 42px;
      text-align: center;
      position: relative;
      margin-bottom: 14rem;
      p {
        font-size: 18px;
        font-weight: 300;
        color: #64758a;
        line-height: 32px;
        opacity: 1;
      }
      .quote-img {
        position: absolute;
        left: -11px;
        top: 0;
        @media (max-width: 768px) {
          display: none;
        }
      }
      .quote-img2 {
        position: absolute;
        bottom: 0;
        right: -12px;
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
}

.test {
  background-color: red;
  padding: 30px;
}