html {
  scroll-behavior: smooth;
}
.error {
  color: #ce1d1d;
  font-size: 15px;
  font-weight: 400;
}
.header {
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
  padding: 22px 0px;
  position: relative;
  &.fixed-top {
    position: fixed !important;
    z-index: 9999;
    background-color: white;
  }
  .header-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 992px) {
      display: none;
    }
    ul {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      li {
        padding: 0px 18px;
        list-style: none;
        @media (min-width: 1280px) and (max-width: 1400px) {
          padding: 0px 12px;
        }
        @media (min-width: 1200px) and (max-width: 1279px) {
          padding: 10px 14px;
        }
        a {
          color: #4A5568;
          font-weight: 500;
          text-decoration: none;
        }
      }
    }
    .contact-btn {
      @extend .primary-btn;
      padding: 13px 22px 15px 22px;
      @media (min-width: 1280px) and (max-width: 1365px) {
        font-size: 14px;
      }
    }
    .visit-btn {
      @extend .primary-btn;
      padding: 11px 22px 13px 22px;
      background-color: #fff;
      border: 1px solid #508e01;
      color: #508e01;
      font-weight: 500;
      display: flex;
      align-items: center;
      @media (min-width: 1280px) and (max-width: 1365px) {
        font-size: 14px;
      }
      > img {
        margin-left: 10px;
      }
    }
  }
  .mobile-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      width: 2.2rem;
      height: 2.2rem;
    }
  }

  @media (min-width: 992px) {
    .mobile-menu {
      display: none;
    }
  }
  @keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -8%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  .mobile-menu-dropdown {
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
    margin-top: 26px;
    position: absolute;
    background: white;
    width: 100%;
    left: 0;
    z-index: 99999;
    top: 60px;
    animation-duration: 0.6s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-name: fadeInDown;
    li {
      padding: 0px;
      margin-bottom: 12px;
      width: max-content;
      list-style: none;
      a {
        color: $heading-color;
        text-decoration: none;
      }
    }
    .contact-btn {
      @extend .primary-btn;
      padding: 9px 22px 11px 22px;
      margin: 6px 0px;
      display: inline-block;
    }
    .visit-btn {
      @extend .primary-btn;
      padding: 10px 22px 12px 22px;
      background-color: #fff;
      border: 1px solid #508e01;
      color: #508e01;
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      > img {
        margin-left: 10px;
      }
    }
  }
}

.footer {
  background-color: $primary-color;
  font-family: "Poppins", sans-serif;
  padding: 50px 0px;
  .newsletter {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 11px;
    padding: 22px 32px;
    @media (max-width: 768px) {
      margin: 2.3rem 0px 1rem 0px;
      display: block;
    }
    .text {
      font-size: 22px;
      margin-bottom: 0;
      color: $primary-color;
      font-weight: 600;
      margin-right: 22px;
      width: 30%;
      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 2rem;
      }
    }
    .newsletter-flex {
      display: flex;
      align-items: center;
      width: 70%;
      @media (max-width: 768px) {
        width: 100%;
      }
      input {
        background: #dbe3ff;
        padding: 14px;
        border: none;
        border-radius: 4px 0px 4px 4px;
        width: 90%;
      }
      .submit-btn {
        @extend .primary-btn;
        padding: 11.4px 32px;
        font-size: 20px;
        font-weight: 600;
        margin-left: -2px;
      }
    }
  }
  hr {
    margin: 33px 0px 46px 0px;
    border: 0.5px solid #fff;
    opacity: 0.8;
  }
  .country {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 2.2rem;
  }
  ul {
    padding-inline-start: 0;
    li {
      font-size: 15px;
      font-weight: 300;
      color: #fff;
      display: flex;
      align-items: flex-start;
      margin-bottom: 22px;
      line-height: 30px;
      &:last-child {
        margin-bottom: 40px;
      }
      .icon {
        margin-right: 18px;
        width: 20px;
        height: 20px;
        margin-top: 6px;
      }
      a {
        font-size: 16px;
        font-weight: 300;
        text-decoration: none;
        color: #fff;
      }
    }
  }
}

.success-msg {
  position: absolute;
  padding: 12px;
  background-color: #508e01;
  right: 10px;
  top: 10px;
}
.Toastify__toast-container--top-right{
  z-index: 99999;
}